import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "vegasdays",
  casinoId: 42,
  GA_TRACKING:'G-TBH5TWFG49',

  rivalChatGroupName: "Vegas Days",
  prettyName: "Vegas Days",
  contactEmail: "support@vegasdays.com",
  docsEmail: "documents@vegasdays.com",

  //SEO
  metaDescription: "Join Casino Vegas Days and Play original Vegas casino slots online, live dealer games, table games like blackjack, roulette, and poker. Bitcoin and Crypto payment, VIP rewards, fast payouts, safe gaming, secure transactions, and easy deposits. Play now and win real cash fast!",
  logoName: "vegas-days-online-casino-logo.png",
  logoAltText: "Vegas Days Casino online logo with classic Las Vegas sign style featuring bold blue and red text popular for slots poker and Vegas-style casino games."
};


